import axios from 'axios';
import { createPinia } from 'pinia';
import { setDefaultProps } from 'vue-tippy';
import { createInertiaApp, router, usePage } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import NotificationsVue from '@aspect/shared/plugins/notifications.ts';
import I18nVue, { currentLocale, currentLocaleTag } from '@aspect/shared/plugins/i18n.ts';

import { gtag } from '@aspect/shared/utils/gtag.ts';
import { setLocale as setDateLocale, setTimezone } from '@aspect/shared/utils/date.ts';
import { getLightness } from '@aspect/shared/utils/text-color.ts';
import { theme } from '@aspect/shared/utils/tailwind.ts';

import useContext from '@aspect/shared/composables/use-context.ts';
import useNotification from '@aspect/shared/composables/use-notification.ts';
import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';

import '@/site/css/main.css';

import type { DefineComponent } from 'vue';

let appIsMounted = false;
const pinia = createPinia();

setDefaultProps({
    arrow: false,
    animation: 'translate',
    offset: [0, 8],
    touch: true,
    theme: 'light',
});

const pageResolver = (name: string) => {
    return resolvePageComponent(
        `/resources/site/${name}.vue`,
        import.meta.glob<DefineComponent>([
            '/resources/site/pages/*.vue',
            '/resources/site/pages/**/page.vue',
            '/resources/site/pages/**/modal.vue',
            '/resources/site/tenants/*/pages/*.vue',
        ]),
    );
};

export const pageTitle = window.document.getElementsByTagName('title')[0]?.innerText;

createInertiaApp({
    progress: {
        color: 'var(--tenant-color-500)',
    },
    resolve: (name) => pageResolver(name),
    setup({ el, App, props, plugin }) {
        const app = createApp({
            setup() {
                const { setContext } = useContext();
                const pageProps = usePageProps(props.initialPage.props);

                axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

                onBeforeMount(() => {
                    if (pageProps.value.user && pageProps.value.tenant) {
                        setContext({ user: pageProps.value.user, tenant: pageProps.value.tenant });
                    }

                    if (pageProps.value.tenant) {
                        setTimezone(pageProps.value.tenant.timezone);
                    }

                    if (pageProps.value.tenant && pageProps.value.tenant.settings.brandColor) {
                        document.documentElement.style.setProperty('--tenant-color-500', pageProps.value.tenant.settings.brandColor);
                        document.documentElement.style.setProperty('--tenant-text-color', getLightness(pageProps.value.tenant.settings.brandColor) === 'light' ? theme.colors.gray['800'] : theme.colors.gray['200']);
                    }
                });

                watch(currentLocale, () => {
                    axios.defaults.headers.common['X-Locale'] = currentLocale.value;
                    setDateLocale(currentLocaleTag.value);
                }, { immediate: true });

                return () => h(App, props);
            },
        });

        const { notification, errors } = useNotification();

        router.on('finish', () => {
            const pageProps = usePageProps();
            const page = usePage();

            notification.value = pageProps.value.notification;
            errors.value = pageProps.value.errors;

            gtag({ event: 'page_view', value: page.url });
        });

        app.use(plugin)
            .use(pinia)
            .use(I18nVue, {
                fallbackLang: 'en',
                resolve: async (lang: string) => {
                    const langs = import.meta.glob('../../../../packages/lang/src/*.json');

                    return await langs[`../../../../packages/lang/src/${lang}.json`]();
                },
                onLoad: () => {
                    if (appIsMounted) {
                        return;
                    }

                    app.mount(el); // Mounted here so translations are loaded before vue.
                    appIsMounted = true;
                },
            });

        app.use(NotificationsVue, { position: 'bottom-right' });

        axios.defaults.withCredentials = true;
        axios.defaults.withXSRFToken = true;
    },
});
